body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

.header {
  font-size: 1.4rem;
  padding: 0.3rem;
  color: #ffe0a0;
  background-color: #442011;
}

.header a:link {
  color: white;
}

.header a:hover {
  color: white;
  text-decoration: underline;
}

.header a:active {
  color: white;
  text-decoration: underline;
}

.header a:visited {
  color: white;
}

.throttle-slider {
  height: 80% !important;
}

::-webkit-scrollbar {
  width: 1px;
  background-color: #213547;
}

::-webkit-scrollbar-thumb {
  background-color: white;
}
