/* Apply existing bp4 translation to left label and move to the right 20px, scale down */
.throttle-range-slider .bp4-slider-label:first-child {
  transform: translate(calc(-50% + 20px), 18px);
}

/* Apply existing bp4 translation to middle label and scale down */
.throttle-range-slider .bp4-slider-label:not(:first-child):not(:last-child) {
  transform: translate(-50%, 18px);
}

/* Apply existing bp4 translation to right label and move to the left 20px, scale down */
.throttle-range-slider .bp4-slider-label:last-child {
  transform: translate(calc(-50% - 20px), 18px);
}
