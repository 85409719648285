.input-mapping-slider {
    opacity: 1 !important;
    cursor: default !important;
}

.input-mapping-slider .bp4-slider-progress:nth-child( -n + 2 ) {
    background-color: red;
}

.input-mapping-slider .bp4-slider-progress:nth-child( 3 ) {
    background-color: green;
}

.hidden-handle > .bp4-slider-handle {
    display: none;
}
