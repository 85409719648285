.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 28px;
    background-color: #939393e2;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    background-color: white;
    transition: transform 0.3s ease;
  }
  
  .switch.active {
    background-color: #28bd28;
  }
  
  .switch.active .slider {
    transform: translateX(22px);
  }
  